<template>
    <recess-modal 
        variant="variant2" 
        :show-modal="isModalVisible" 
        @close="$emit('close')"
    >
        <template slot="modal-title">
            <h3 class="qa-edit-courses-in-group-pop-up-title">{{ PROVIDER.Portfolio.PortfolioOverview.Popup.EditCoursesInGroup.Title }}</h3>
            <p class="qa-edit-courses-in-group-pop-up-information">
                {{ `${PROVIDER.Portfolio.PortfolioOverview.Popup.EditCoursesInGroup.Description} (${coursesTotalCount})` }} 
            </p>
        </template>

        <template slot="modal-body">	
            <recess-multi-select
                :selected-options="selectedOption"
                :options="selectOptions" 
                :single-select="true" 
                :placeholder="PROVIDER.Portfolio.PortfolioOverview.Popup.EditCoursesInGroup.Action.Placeholder"
                :select-options-text="PROVIDER.Portfolio.PortfolioOverview.Popup.EditCoursesInGroup.Action.Placeholder"
                @input="(newValue) => onOptionChange(newValue)"
            />

            <segments-and-subsegments 
                v-if="showSegments" 
                :courses-ids="courses"
				:triggerValidation="manuallyTriggerValidation"
				@manuallyTriggerValidation="(value) => manuallyTriggerValidation = value"
            />  

            <subsidies 
                v-if="showSubsidy" 
                :subsidy-value="localSubsidies" 
                @triggerSubsidiesValue="setNewSubsidies" 
            />
        </template>

        <template slot="modal-footer">
            <div class="row no-gutters justify-content-end align-items-center">
                <recess-button 
                    variant="tertiary"  
                    class="col-12 col-md-3 qa-edit-courses-in-group-cancel-button" 
                    :title="BUTTON_TEXT.cancel"
                    @click.native.prevent="cancelHandler" 
                />
                <recess-button 
                    variant="secondary" 
                    class="col-12 col-md-3 ml-3 qa-edit-courses-in-group-submit-button" 
                    :title="saveButtonText" 
                    @click.native.prevent="submitHandler" 
                />
            </div>
        </template>
    </recess-modal>
</template>

<script>
import { mapActions } from 'vuex'

import { deleteItem } from '../../../../shared/api/SharedClient'
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'
import { BUTTON_TEXT, API_CALL_URL_PATHS } from '@/../../shared/constants/constantsGeneral.json'
import { defaultErrorMessage, defaultNotFoundErrorMessage, portfolioImportSuccessAction } from '../../../../shared/constants/validationMessageHelper'

const SegmentsAndSubsegments = () => import('@/components/organisms/Portfolio/CourseShared/SegmentsAndSubsegments')
const Subsidies = () => import('@/components/organisms/Portfolio/CourseEdit/Subsidies')

export default {
	name: 'EditCoursesInGroupPopupComponent',
	components: {
		SegmentsAndSubsegments,
		Subsidies
	},
	props: {
		isModalVisible: {
			type: Boolean,
			default: false
		},
		courses: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			PROVIDER,
			BUTTON_TEXT,
			timeout: null,
			selectedOption: null,
			isValidData: true,
			getSelectedCoursesId: [],
			localSubsidies: [],
			selectOptions: [
				{
					displayText: PROVIDER.Portfolio.PortfolioOverview.SelectOptionsLabels.Segment,
					value: 'segment'
				},
				{
					displayText: PROVIDER.Portfolio.PortfolioOverview.SelectOptionsLabels.Subsidy,
					value: 'subsidy'
				},
				{
					displayText: PROVIDER.Portfolio.PortfolioOverview.SelectOptionsLabels.Delete,
					value: 'deleted'
				}
			],
			manuallyTriggerValidation: false
		}
	},
	computed: { 
		saveButtonText() {
			return this.selectedOption === 3 ? BUTTON_TEXT.delete : BUTTON_TEXT.save
		},
		coursesTotalCount() {
			return this.courses.length
		},
		showSegments() {
			return this.selectedOption === 'segment'
		},
		showSubsidy() {
			return this.selectedOption === 'subsidy'
		}
	},
	watch: {
		isModalVisible: function watchPropsChange(newValue) {
			if (newValue === true) {
				this.getSelectedCoursesId = JSON.parse(JSON.stringify(this.courses))
				this.resetData() 
			}else {
				this.manuallyTriggerValidation = false
			}
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.$bus.$on('trigger-validation-message', this.triggerValidations)
		})
	},
	beforeDestroy() {
		clearTimeout(this.timeout)
		this.$bus.$off('trigger-validation-message')
	},
	methods: {  
		...mapActions('courseModule', ['patchCourse']),

		setNewSubsidies(newValue) { 
			this.localSubsidies = newValue 
		},		
		async deleteGroupCourses() {
            let toastNotification =  {}

			this.courses.forEach( async course => { 
				await deleteItem(
                    process.env.VUE_APP_PROVIDER_API_URL,
                    `${API_CALL_URL_PATHS.courses}/${course.courseId}`,
                    false
                ).then(() => {
					toastNotification = {
						type: 'success',
						message: portfolioImportSuccessAction('verwijderd')
					}
				}).catch(error => {
					toastNotification = {
						type: 'error',
						message: defaultErrorMessage
					}
                	if (error.response.data?.error?.code === '404') {
                    	toastNotification.message = defaultNotFoundErrorMessage('leeraanbod')
                	} 
				}) 

            	this.$store.dispatch('toastNotificationModule/add', toastNotification, {
                	root: true
            	})                    

			})
			this.closeModal()
		},
		submitHandler() {
			if(this.selectedOption === 'subsidy' ) {
				const subsidieToPatch = this.localSubsidies.map(property => {
					if (property !== null) {
						return { "value": `${property}` }
					}
					return null
				})  

				const objectToPatch = [{
					value: subsidieToPatch,
					path: 'subsidies',
					op: 'replace'	
				}]

				const patchProperty = 'Subsidie'
				
				this.courses.forEach(selectedCourse => { 
					this.patchCourse({courseId: selectedCourse.courseId, objectToBePatched: objectToPatch, patchProperty})
				});
			}

			if(this.selectedOption === 'segment') {
				if(this.isValidData) {
					this.$bus.emit('update-courses-segments') 

				} else {
					this.manuallyTriggerValidation = true
				}
			}

			if (this.selectedOption === 'deleted') {
				this.deleteGroupCourses()
			}
		},
		onOptionChange(newValue) {
			this.selectedOption = newValue
		},
		triggerValidations(newValue) {
			this.isValidData = newValue
		},
		cancelHandler() {
			this.$emit('close')
		},
		closeModal() {
			let timeoutValue = 3000
				if(process.env.NODE_ENV !== 'production'){
					timeoutValue = 15000
				};
			this.timeout = setTimeout(() => {
            	this.$bus.emit('refreshCourseList')
				this.$emit('close')
			}, timeoutValue)
		},
		resetData() {
			this.timeout = null
			this.selectedOption = null
			this.localSubsidies = null
		}
	}
}
</script>